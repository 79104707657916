import io from "socket.io-client";

let socket;

const connectSocket = (token) => {
  socket = io(process.env.REACT_APP_SERVICE_BASE_URL, {
    path: "/socket",
    auth: {
      token,
    },
  });
};

const initializeSocket = (token) => {
  if (!socket) {
    socket = io(`${process.env.REACT_APP_SERVICE_BASE_URL}`, {
      path: "/socket",
      auth: {
        token,
      },
      transports: ['websocket'],
    });

    socket.on("connect_error", (err) => {
      console.error("Connection to socket server failed:", err);
    });

    socket.on("connect", () => {
      console.log("Successfully connected to socket server.");
    });
  }
  return socket;
};

const getSocket = (token) => {
  return socket || initializeSocket(token);
};

export { getSocket, connectSocket, socket };
