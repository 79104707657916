import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import useSocket from "../../hooks/useSocket";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import { useAxios } from "../../hooks";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { withAlert } from "../../recoil/snackbar";
import { LoadingButton } from "@mui/lab";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudSyncRoundedIcon from "@mui/icons-material/CloudSyncRounded";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import SocketEvents from "../../utilities/socketEvent";
import authAtom from "../../recoil/auth/atom";
import { connectSocket, socket } from "../../socket";

export default function ReportAction() {
  const auth = useRecoilValue(authAtom);
  const api = useAxios();
  // const socket = useSocket(auth);
  const openAlert = useSetRecoilState(withAlert);

  const [startValue, setStartValue] = useState(dayjs("2024-04-17"));
  const [endValue, setEndValue] = useState(dayjs("2024-04-17"));
  const today = new Date();

  const [fileData, setFileData] = useState(null);
  const [isDatePickerDisabled, setIsDatePickerDisabled] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const { mutate: generateReport, isLoading } = useMutation(
    () => {
      const startDateString = `${startValue.format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      )}`;
      const endDateString = `${endValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}`;
      return api.get(
        `/api/report/usage-report?start_date=${startDateString}&end_date=${endDateString}`
      );
    },
    {
      onSuccess: (res) => {
        openAlert({
          status: 200,
          detail: "Report generation started....",
        });
        // setFileData(res.data.data);
        setIsGenerating(true);
        setIsDatePickerDisabled(true);
      },
    }
  );

  const { mutate: downloadReport, isLoading: isDownloading } = useMutation(
    () =>
      api.get(`/api/report/usage-report-download?file_name=${fileData}`, {
        responseType: "blob",
      }),
    {
      onSuccess: (response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" })
        );
        const a = document.createElement("a");
        a.href = url;
        a.download = `${fileData?.split(".")[0]}.zip`;
        a.click();
        URL.revokeObjectURL(url);
        openAlert({
          status: 200,
          detail: "Report downloaded successfully.",
        });
        setFileData(null);
        setIsDatePickerDisabled(false);
      },
    }
  );

  const handleReportAction = () => {
    if (fileData === null) {
      generateReport();
    } else {
      downloadReport();
    }
  };

  useEffect(() => {
    if (fileData !== null) {
      setIsDatePickerDisabled(true);
    }
  }, [fileData, startValue, endValue]);

  useEffect(() => {
    // if (!socket) return;
    if(!socket) {
      connectSocket(auth.access_token)
    }
    socket.on("connect", () => {
      console.log("connect: ");
    }); 
    const handleFileUpdate = (data) => {
      console.log("handle file", data);
      setIsGenerating(false);
      setFileData(data);
    };
    socket.on(SocketEvents.ADMIN_REPORT_STATUS_UPDATED, handleFileUpdate);
    return () => {
      socket.off(SocketEvents.ADMIN_REPORT_STATUS_UPDATED, handleFileUpdate);
    };
  }, []);

  return (
    <>
      <Box>
        <Box
          sx={{
            mb: "2.5rem",
          }}
        >
          <Typography
            fontWeight="bold"
            color="primary"
            sx={{ my: "0.5rem", fontSize: "1.2rem" }}
          >
            Monthly Usage Report
          </Typography>
          <Typography>
            You can generate and download monthly usage reports with date range
            here!
          </Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" gap={4} flexWrap="wrap">
            <DatePicker
              format="YYYY-MM-DD"
              value={startValue}
              onChange={(newValue) => setStartValue(newValue)}
              renderInput={(params) => <TextField {...params} />}
              label="Start Date"
              slotProps={{ textField: { size: "small" } }}
              disabled={isDatePickerDisabled}
            />
            <DatePicker
              format="YYYY-MM-DD"
              value={endValue}
              onChange={(newValue) => setEndValue(newValue)}
              maxDate={dayjs(today)}
              renderInput={(params) => <TextField {...params} />}
              label="End Date"
              slotProps={{ textField: { size: "small" } }}
              disabled={isDatePickerDisabled}
            />
            <Box sx={{ position: "relative", display: "inline-block" }}>
              <LoadingButton
                disableElevation
                variant="contained"
                sx={{
                  height: "3.5rem",
                }}
                onClick={handleReportAction}
                disabled={isGenerating || isDownloading}
              >
                {isGenerating ? (
                  <>
                    <AutorenewRoundedIcon
                      sx={{
                        "@keyframes spin": {
                          from: { transform: "rotate(0deg)" },
                          to: { transform: "rotate(360deg)" },
                        },
                        animation: "spin 1.5s linear infinite",
                      }}
                    />
                    &nbsp; Report Generating
                  </>
                ) : fileData !== null ? (
                  <>
                    <CloudDownloadIcon /> &nbsp; Download Report
                  </>
                ) : (
                  <>
                    <CloudSyncRoundedIcon /> &nbsp; Generate Report
                  </>
                )}
              </LoadingButton>
              <Tooltip
                title="Generating report may take longer time when there is a large amount of data."
                placement="top"
              >
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "-0.7rem",
                    right: -2,
                    transform: "translate(50%, -50%)",
                    color: "primary.main",
                  }}
                >
                  <ErrorOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </LocalizationProvider>
      </Box>
    </>
  );
}
